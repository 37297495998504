import React from 'react';
import './whatTCA.css';
import Feature from '../../components/feature/Feature';
import { motion } from 'framer-motion';

const WhatTCA = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
      viewport={{ once: true }}
      className='tca__whattca section__margin'
      id='whattca'
    >
      <div className='tca__whattca-feature'>
        <Feature
          title='What is The Community Alliance (TCA) ?'
          text='We are a passionate group of individuals driven by the belief that positive change begins with empowered individuals. Our mission is clear: to uplift, educate, and inspire, fostering a ripple effect of transformation throughout our community.'
        />
      </div>
      <div className='tca__whattca-heading'>
        <h1 className='gradient__text-primary'>Network, Explore & Innovate.</h1>
      </div>
      <div className='tca__whattca-container'>
        <Feature
          title='Why TCA?'
          text='We empower individuals, nurture innovation, and cultivate leadership to create positive change in our communities. Join us in making a lasting impact and being part of a supportive community dedicated to a brighter future.'
        />
        <Feature
          title='Concept of TCA'
          text='Our core concept revolves around education, mentorship, and technology as catalysts for personal and collective growth. Through workshops and outreach programs, we aim to nurture innovation and leadership development, driving transformative impact.'
        />
        <Feature
          title='Target audience of TCA'
          text='We welcome anyone seeking education, mentorship, and opportunities to make a difference in their community.'
        />
      </div>
    </motion.div>
  );
};

export default WhatTCA;
