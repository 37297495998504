import React from 'react';
import './header.css';
import heroImage from '../../assets/tca_hero-cropped.svg';

const Header = () => {
  return (
    <div className='tca__header section__padding' id='home'>
      <div className='tca__header-content'>
        <h1 className='gradient__text-primary'>The Community Alliance (TCA)</h1>
        <h2 className='gradient__text-secondary'>Empowering Individuals & Igniting Change</h2>
        <p>
          Welcome to the Community Alliance, a non-profit organization with a mission to transform lives and communities. Through the pillars of
          education, mentorship, and technology, we empower individuals to become catalysts for positive change.
        </p>
        <div className='tca__header-content__input'>
          <a
            href='//forms.microsoft.com/pages/responsepage.aspx?id=oBzDhDusrk6tEVGdgCM-b0RxnAoboPlFgIgpaFieR5pUM0MwRTVNRlhKOFdXNFVBVVlJQ0o3Q0ZWMC4u'
            target='_blank'
            rel='noreferrer'
          >
            <button type='button'>Become a Member!</button>
          </a>
        </div>
      </div>
      <div className='tca__header-image'>
        <img src={heroImage} alt='community' />
      </div>
    </div>
  );
};

export default Header;
