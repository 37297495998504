import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import './navbar.css';
import logo from '../../assets/tca_logo2.png';
// import zabLogo from '../../assets/zabtech/Logo f2 .png';

const Menu = () => (
  <>
    <p>
      <Link to='/home'>Home</Link>
    </p>
    <p>
      <Link to='/events'>Events & Workshops</Link>
    </p>
    <p>
      <Link to='/team'>Team</Link>
    </p>
    {/* <p>
      <Link to='/zabcon'>ZABCON'24</Link>
    </p> */}
  </>
);

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const url = window.location.href.split('/');
  const currentUrl = url[url.length - 1];

  return (
    <div className='tca__navbar'>
      <div className={`tca__navbar-links_logo ${currentUrl === 'zabtechfusion' && ''}`}>
        <a href='/home'>
          <img src={currentUrl === 'zabtechfusion' ? logo : logo} alt='logo' />
        </a>
      </div>
      <div className='tca__navbar-links'>
        <div className={`tca__navbar-links_container ${currentUrl === 'zabtechfusion' && ''}`}>
          <Menu />
        </div>
      </div>
      <div className={`tca__navbar-sign ${currentUrl === 'zabtechfusion' && ''}`}>
        <a
          href='//forms.microsoft.com/pages/responsepage.aspx?id=oBzDhDusrk6tEVGdgCM-b0RxnAoboPlFgIgpaFieR5pUM0MwRTVNRlhKOFdXNFVBVVlJQ0o3Q0ZWMC4u'
          target='_blank'
          rel='noreferrer'
        >
          <button type='button' id='signUp'>
            Join Us!
          </button>
        </a>
      </div>
      <div className='tca__navbar-menu'>
        {toggleMenu ? (
          <RiCloseLine color='#040c18' size={27} onClick={() => setToggleMenu(false)} />
        ) : (
          <RiMenu3Line color='#040c18' size={27} onClick={() => setToggleMenu(true)} />
        )}
        {toggleMenu && (
          <div className={`tca__navbar-menu_container ${currentUrl === 'zabtechfusion' && ''} scale-up-center`}>
            <div className='tca__navbar-menu_container-links'>
              <Menu />
              <div className='tca__navbar-menu_container-links-sign'>
                <button type='button' id='signUp'>
                  <a
                    href='//forms.microsoft.com/pages/responsepage.aspx?id=oBzDhDusrk6tEVGdgCM-b0RxnAoboPlFgIgpaFieR5pUM0MwRTVNRlhKOFdXNFVBVVlJQ0o3Q0ZWMC4u'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Join Us!
                  </a>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
