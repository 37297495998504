import React from 'react';
import tcaLogo from '../../assets/tca_logo2.png';
import './footer.css';
import instagramLogo from '../../assets/instagram.png';
import linkedinLogo from '../../assets/linkedin.png';
import emailLogo from '../../assets/email.png';
import givemycertifcate from '../../assets/givemycertificate.png';
import dataCampLogo from '../../assets/datacamp.png';
import githubLogo from '../../assets/github_logo2.png';
// import szabistLogo from '../../assets/szabist-logo-withouttext.png';
// import mlsaLogo from '../../assets/mlsaszabist_logo.png';

const Footer = () => {
  const url = window.location.href.split('/');
  const currentUrl = url[url.length - 1];

  return (
    <div className='tca__footer section__padding'>
      {/* {currentUrl === 'zabtechfusion' ? (
        <>
          <div className='tca__footer-heading'>
            <h1 className='gradient__text'>Powered By</h1>
            <div className='partners__logos'>
              <img src={githubLogo} alt='Github' />
            </div>
          </div>
          <div className='tca__footer-heading'>
            <h2 className='gradient__text'>Partners</h2>
            <div style={{ margin: '1rem 0' }}>
              <img src={mlsaLogo} alt='MLSA ZABIST Islamabad' className='community_Partner_logo' />
            </div>
            <div className='partners__logos'>
              <img src={szabistLogo} alt='SZABIST Islamabad' />
              <img src={givemycertifcate} alt='Give my certificate' />
              <img src={dataCampLogo} alt='Data camp' />
            </div>
          </div>
        </>
      ) : (
        <div className='tca__footer-heading'>
          <h1 className='gradient__text'>Partners</h1>
          <div className='partners__logos'>
            <img src={givemycertifcate} alt='Give my certificate' />
            <img src={dataCampLogo} alt='Data camp' />
            <img src={githubLogo} alt='Github' />
          </div>
        </div>
      )} */}
      <div className='tca__footer-heading'>
        <h1 className='gradient__text'>Partners</h1>
        <div className='partners__logos'>
          <img src={givemycertifcate} alt='Give my certificate' />
          <img src={dataCampLogo} alt='Data camp' />
          <img src={githubLogo} alt='Github' />
        </div>
      </div>
      <div className='tca__footer-links'>
        <div className='tca__footer-links_logo'>
          <img src={tcaLogo} alt='tca_logo' />
          <div className='tca__footer-links_container'>
            <a href='https://www.linkedin.com/company/the-community-alliance-tca/'>
              <img src={linkedinLogo} alt='LinkedIn Logo' className='social__media__icon' />
            </a>
            <a href='https://www.instagram.com/comm_alliance/'>
              <img src={instagramLogo} alt='Instagram Logo' className='social__media__icon' />
            </a>
            <a href='mailto:communityalliance.tca@gmail.com'>
              <img src={emailLogo} alt='Mail Logo' className='social__media__icon' />
            </a>
          </div>
        </div>
        <div className={`tca__footer-links_div ${currentUrl === 'zabtechfusion' && ''}`}>
          <h4>Our Links</h4>
          <a href='/code-of-conduct'>Code of Conduct</a>
        </div>
        <div className={`tca__footer-links_div ${currentUrl === 'zabtechfusion' && ''}`}>
          <h4>MLSA - SZABIST Islamabad</h4>
          <a href='https://www.linkedin.com/company/mlsa-szabist-isb/' target='_blank' rel='noreferrer'>
            Linkedin
          </a>
          <a href='https://www.instagram.com/mlsaszabist_isb/' target='_blank' rel='noreferrer'>
            Instagram
          </a>
          <a href='https://chat.whatsapp.com/LiYIjNbAIzVDdb2hzrUOBs' target='_blank' rel='noreferrer'>
            Whatsapp Group
          </a>
          <a href='mailto:communityalliance.tca@gmail.com' target='_blank' rel='noreferrer'>
            Gmail
          </a>
        </div>
        <div className={`tca__footer-links_div ${currentUrl === 'zabtechfusion' && ''}`}>
          <h4>GDSC - SZABIST Islamabad</h4>
          <a href='https://www.linkedin.com/company/gdsc-szabist-islamabad/' target='_blank' rel='noreferrer'>
            Linkedin
          </a>
          <a href='https://www.instagram.com/gdscszabist_isb/' target='_blank' rel='noreferrer'>
            Instagram
          </a>
          <a href='https://twitter.com/gdsc_szabistISB' target='_blank' rel='noreferrer'>
            Twitter
          </a>
          <a
            href='https://gdsc.community.dev/shaheed-zulfikar-ali-bhutto-institute-of-science-and-technology-szabist-islamabad-campus/'
            target='_blank'
            rel='noreferrer'
          >
            Community Website
          </a>
        </div>
      </div>

      <div className='tca__footer-copyright'>
        <p>Copyright © 2023 TCA. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
