import React from 'react';
import errorIcon from '../../assets/error-404.png';
import './notFoundPage.css';
const NotFoundPage = () => {
  return (
    <div className='error_Container'>
      <img src={errorIcon} alt='' />
      <h1>Opps! Page not found!</h1>
    </div>
  );
};

export default NotFoundPage;
