import { useState, useEffect } from 'react';
import './teamPage.css';
import teamHero from '../../assets/teamHero-cropped.svg';
import ateeq from '../../assets/ateeqRana7.png';
import zia from '../../assets/zia.jpg';
import ProfileCard from '../../components/profileCard/ProfileCard';
import mubeen from '../../assets/mubeen_mahmood2.jpg';
import eman from '../../assets/Eman_Fatima2.png';
import salman from '../../assets/salman.jpg';
import zainabS from '../../assets/Zainab Sakhawat.png';
import talhaShah from '../../assets/talhaShah.jpg';
import ahsanKhalid from '../../assets/ahsanKhalid.jpg';
import mairaJaved from '../../assets/mairaJaved2.jpg';
import talha2 from '../../assets/talha22.jpg';
import yousaf from '../../assets/yusuf.jpg';
import ghulam from '../../assets/ghulam.jpg';
import ahmedAsjal from '../../assets/ahmedasjal.jpg';
import nimra from '../../assets/nimra.jpg';
import ahmedEMusfafa from '../../assets/ahmedMustafa.jpg';
import shehroz from '../../assets/shehroz.jpg';
import { motion } from 'framer-motion';
import 'animate.css';

const TeamPage = () => {
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    const imageElements = document.querySelectorAll('.profile-image'); // Select all image elements with the class 'profile-image'

    const loadImages = () => {
      let loadedCount = 0;
      imageElements.forEach((image) => {
        if (image.complete) {
          loadedCount++;
        }
      });

      if (loadedCount === imageElements.length) {
        setImagesLoaded(true);
      }
    };

    loadImages(); // Check if some images are already loaded on component mount

    imageElements.forEach((image) => {
      image.addEventListener('load', loadImages);
    });

    return () => {
      imageElements.forEach((image) => {
        image.removeEventListener('load', loadImages);
      });
    };
  }, []);

  if (!imagesLoaded) {
    return (
      <div className='animate__animated animate__fadeIn'>
        <div className='loading-indicator'>Loading...</div>
      </div>
    );
  } else {
    return (
      <div className='animate__animated animate__fadeIn'>
        <div className='teamPage__container'>
          <div className='teamPage__section_main'>
            <header className='teamPage__section-header'>
              <h1 className='gradient__text-primary' style={{ marginBottom: '1rem' }}>
                Meet our Team
              </h1>
              <p>
                The success of The Community Alliance (TCA) is driven by the dedication, passion, and vision of our organizers. Our team is composed
                of individuals who are deeply committed to our mission of empowering individuals and creating positive change within communities. Get
                to know the dynamic leaders who steer the course of TCA:
              </p>
            </header>
            <div className='teamPage__section-body diversity_img'>
              <img src={teamHero} alt='' srcset='' className='diversity_img' />
            </div>
          </div>
          <div className='teamPage__section'>
            <header className='teamPage__section-header'>
              <h1 className='gradient__text-primary'>Founder</h1>
            </header>
            <div className='teamPage__section-body'>
              <ProfileCard name='Muhammad Zia Un Nabi Janjua' position='President' fileName={zia} link='https://www.linkedin.com/in/rajazia442/' />
            </div>
          </div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className='teamPage__section alt_background '
          >
            <header className='teamPage__section-header'>
              <h1 className='gradient__text-secondary'>Organizers</h1>
            </header>
            <div className='teamPage__section-body'>
              <ProfileCard name='Eman Fatima' position='Vice President' fileName={eman} link='https://www.linkedin.com/in/eman-fatima-5034a9224/' />
              <ProfileCard
                name='Mubeen Mahmood'
                position='Documentation & Reporting Coordinator'
                fileName={mubeen}
                link='https://www.linkedin.com/in/mubeen-mahmood-4b332124a/'
              />
              <ProfileCard
                name='Salman Karim'
                position='Tech Operations Manager'
                fileName={salman}
                link='https://www.linkedin.com/in/salman-pixel/'
              />
              <ProfileCard
                name='Talha Shah Bukhari'
                position='Event Coordinator'
                fileName={talhaShah}
                link='https://www.linkedin.com/in/talha-shah-950a21266/'
              />

              <ProfileCard
                name='Zainab Sakhawat'
                position='Communications Manager'
                fileName={zainabS}
                link='https://www.linkedin.com/in/zainab-sakhawat-86a2b9224'
              />
              <ProfileCard name='Ateeq Rana' position='Web Development Coordinator' fileName={ateeq} link='https://www.linkedin.com/in/ateeqrana/' />
              <ProfileCard
                name='Ahsan Khalid'
                position='Membership Coordinator'
                fileName={ahsanKhalid}
                link='https://www.linkedin.com/in/ahsankhalid22/'
              />
              <ProfileCard
                name='Maira Javed'
                position='Technical Coordinator'
                fileName={mairaJaved}
                link='https://www.linkedin.com/in/maira-javed11/'
              />
              <ProfileCard name='Muhammad Shehroz' position='Social Media Manager' fileName={shehroz} link='#' />
              <ProfileCard name='Nimra Khalid' position='Operations Manager ' fileName={nimra} link='#' />
              <ProfileCard name='Muhammad Talha' position='Activity Coordinator' fileName={talha2} link='https://www.linkedin.com/in/mtalha10/' />
              <ProfileCard name='Yousaf Khan' position='Creative Director' fileName={yousaf} link='https://www.linkedin.com/in/yousafness/' />
              <ProfileCard name='Ghulam Mustafa' position='Graphic Designer' fileName={ghulam} link='https://www.linkedin.com/in/ghulammustafa06/' />
              <ProfileCard
                name='Ahmed Asjal'
                position='Recruiting Coordinator'
                fileName={ahmedAsjal}
                link='https://www.linkedin.com/in/ahmedasjal/'
              />
              <ProfileCard name='Ahmed E Mustafa' position='Video Editor' fileName={ahmedEMusfafa} link='#' />
            </div>
          </motion.div>
        </div>
      </div>
    );
  }
};

export default TeamPage;
