import { useState } from 'react';
import eventsHero from '../../assets/events_hero-cropped.svg';
import './eventPage.css';
import amazonEvent from '../../assets/amazonEvent.webp';
import infoEvent from '../../assets/infoEvent.webp';
import EventCard from '../../components/eventCard/EventCard';
import 'animate.css';
import zabtechEvent from '../../assets/zabtech_event.jpg';
import datScience from '../../assets/dataScienceOnline.jpg';
import cloudJam from '../../assets/cloudJam.webp';
import web3One from '../../assets/EP01.jfif';
import web3Two from '../../assets/EP01 (1).jfif';
import web3Three from '../../assets/EP01 (2).jfif';
import solutionChallenge from '../../assets/solutionchallenge.webp';
import flutterEvent from '../../assets/flutterevent.webp';
import uiuxEvent from '../../assets/uiuxevent.webp';
import buildResumeEvent from '../../assets/buildResumeEvent.jpg';
import ProfileCard from '../../components/profileCard/ProfileCard';
import ghulam from '../../assets/ghulam.jpg';
import zia from '../../assets/zia.jpg';
import salman from '../../assets/salman.jpg';
import aiEvent from '../../assets/ai_event_mini.jpg';
import techEvent from '../../assets/tech-event.png';
import asadQadir from '../../assets/asad_ullah_qadir.jpg';

const eventData = [
  {
    image: zabtechEvent,
    details: 'DEC 26, 2023 - MEGA EVENT',
    heading: `'ZabTechFusion 23'`,
    description: `Embark on a day of immersive tech experiences with the carefully crafted agenda that unfolded at Zab Tech Fusion 23! The event kicked off with registration from 10:00 AM to 10:45 AM, ensuring a smooth check-in process. The excitement continued with an introduction to MLSA SZABIST at 11:00 AM, providing insights into the innovative community behind the event. Participants delved into the world of GitHub and open source from 11:10 AM to 11:30 AM in the session "GitHub for Students and Open Source 101." Following this, two engaging workshops awaited: "Web Dev: Mastering React and JS" from 11:30 AM to 1:00 PM, and "Visualizing Insights: Python Mastery with Data Camp" from 1:00 PM to 2:00 PM. The afternoon promised Octocat challenges and other fun activities from 2:00 PM to 3:00 PM, followed by a celebratory Closing Ceremony at 3:30 PM to 4:00 PM. The day was capped off with a delightful lunch and networking opportunity from 4:00 PM to 5:00 PM. Participants didn't miss out on the chance to immerse themselves in a day of learning, networking, and enjoyment at Zab Tech Fusion 23!`,
    organizers: [
      <ProfileCard name='Muhammad Zia Un Nabi Janjua' position='President' fileName={zia} link='https://www.linkedin.com/in/rajazia442/' />,
    ],
    detailsLink: 'https://www.linkedin.com/company/mlsa-szabist-isb/posts/?feedView=all',
  },
  {
    image: buildResumeEvent,
    details: 'OCT 07, 2023 - WORKSHOP',
    heading: 'Build a resume website',
    description: `If you missed the event, you lost out on the chance to make your job application more appealing to recruiters while acquiring new skills. During the workshop, attendees were guided through the process of creating a resume website, all without the need to download coding tools or possess prior programming knowledge. By the end of the event, participants were capable of sharing a link for online resume viewing. The prerequisites included having Microsoft Edge, Google Chrome, and a GitHub account. The workshop's agenda encompassed setting up a coding environment, mastering basic HTML for resume structuring, completing resume content, customizing resumes with CSS, and publishing resume websites using GitHub Pages. Attendees received a Certificate of Participation and even had a shot at winning a LinkedIn Premium Voucher as a reward.`,
    organizers: [
      <ProfileCard name='Ghulam Mustafa' position='Graphic Designer' fileName={ghulam} link='https://www.linkedin.com/in/ghulammustafa06/' />,
      <ProfileCard name='Salman Karim' position='Tech Operations Manager' fileName={salman} link='https://www.linkedin.com/in/salman-pixel/' />,
    ],
    detailsLink:
      'https://www.linkedin.com/posts/mlsa-szabist-isb_msftstudentambassadors-activity-7115292874484690944-Fsmy?utm_source=share&utm_medium=member_desktop',
  },
  {
    image: flutterEvent,
    details: 'MAR 15 - 21, 2023 - WORKSHOP / STUDY GROUP',
    heading: 'Flutter: 7 Days Bootcamp',
    link: 'https://gdsc.community.dev/events/details/developer-student-clubs-shaheed-zulfikar-ali-bhutto-institute-of-science-and-technology-szabist-islamabad-campus-presents-flutter-7-days-bootcamp/',
  },
  {
    image: uiuxEvent,
    details: 'MAR 11, 2023 - SPEAKER SESSION / TECH TALK',
    heading: 'Crafting Engaging UI/UX',
    link: 'https://gdsc.community.dev/events/details/developer-student-clubs-shaheed-zulfikar-ali-bhutto-institute-of-science-and-technology-szabist-islamabad-campus-presents-crafting-engaging-uiux/',
  },
  {
    image: solutionChallenge,
    details: 'FEB 23, 2023 - DEMO DAY',
    heading: 'Google Solution Challenge 2023',
    link: 'https://gdsc.community.dev/events/details/developer-student-clubs-shaheed-zulfikar-ali-bhutto-institute-of-science-and-technology-szabist-islamabad-campus-presents-google-solution-challenge-2023/',
  },
  {
    image: web3Three,
    details: 'JAN 27, 2023 - SPEAKER SESSION / TECH TALK',
    heading: 'Dimension Shift: Navigating the Metaverse',
    link: 'https://gdsc.community.dev/events/details/developer-student-clubs-shaheed-zulfikar-ali-bhutto-institute-of-science-and-technology-szabist-islamabad-campus-presents-dimension-shift-navigating-the-metaverse/',
  },
  {
    image: web3Two,
    details: 'JAN 25, 2023 - SPEAKER SESSION / TECH TALK',
    heading: 'Digital Currencies and NFTs',
    link: 'https://gdsc.community.dev/events/details/developer-student-clubs-shaheed-zulfikar-ali-bhutto-institute-of-science-and-technology-szabist-islamabad-campus-presents-digital-currencies-and-nfts/',
  },
  {
    image: web3One,
    details: 'JAN 24, 2023 - SPEAKER SESSION / TECH TALK',
    heading: "Unlocking the Blockchain: A Beginner's Guide",
    link: 'https://gdsc.community.dev/events/details/developer-student-clubs-shaheed-zulfikar-ali-bhutto-institute-of-science-and-technology-szabist-islamabad-campus-presents-unlocking-the-blockchain-a-beginners-guide/',
  },
  {
    image: cloudJam,
    details: 'DEC 30, 2022 - CLOUD STUDY JAM',
    heading: 'Cloud Study Jam - First Step into GCP',
    link: 'https://gdsc.community.dev/events/details/developer-student-clubs-shaheed-zulfikar-ali-bhutto-institute-of-science-and-technology-szabist-islamabad-campus-presents-cloud-study-jam-first-step-into-gcp/',
  },
  {
    image: datScience,
    details: 'DEC 17, 2022 - SPEAKER SESSION / TECH TALK',
    heading: 'How to chose your career in Data Science',
    link: 'https://gdsc.community.dev/events/details/developer-student-clubs-shaheed-zulfikar-ali-bhutto-institute-of-science-and-technology-szabist-islamabad-campus-presents-how-to-chose-your-career-in-data-domain/',
  },
  {
    image: amazonEvent,
    details: 'DEC 13, 2022 - HANDS-ON WORKSHOP',
    heading: 'Hands-on workshop on Amazon, Virtual Assistant and E-commerce',
    link: 'https://gdsc.community.dev/events/details/developer-student-clubs-shaheed-zulfikar-ali-bhutto-institute-of-science-and-technology-szabist-islamabad-campus-presents-how-to-earn-from-amazon-hands-on-workshop-on-amazon-virtual-assistant-and-e-commerce/',
  },
  {
    image: infoEvent,
    details: 'SEP 23, 2022 - CAREER DEVELOPMENT',
    heading: 'Introduction to GDSC - SZABIST Islamabad',
    link: 'https://gdsc.community.dev/events/details/developer-student-clubs-shaheed-zulfikar-ali-bhutto-institute-of-science-and-technology-szabist-islamabad-campus-presents-introduction-to-gdsc-szabist-islamabad/',
  },
];

const eventsPerPage = 6;

const EventPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * eventsPerPage;
  const endIndex = startIndex + eventsPerPage;
  const currentEvents = eventData.slice(startIndex, endIndex);
  const totalPages = Math.ceil(eventData.length / eventsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className='animate__animated animate__fadeIn'>
      <div className='teamPage__container'>
        <div className='teamPage__section_main'>
          <header className='teamPage__section-header'>
            <h1 className='gradient__text-primary'>Events & Workshops</h1>
            <h2 className='gradient__text-secondary' style={{ marginBottom: '1rem' }}>
              Igniting Transformation
            </h2>
            <p>
              At The Community Alliance (TCA), our commitment to positive change extends to the dynamic events and transformative workshops we offer.
              We believe that learning, growth, and innovation are key drivers of personal and community development. That's why our events and
              workshops are designed to empower individuals and communities to thrive.
            </p>
            <ul className='events_types_list'>
              <li>Diverse Range of Topics</li>
              <li>Expert Facilitators</li>
              <li>Interactive Learning</li>
              <li>Community Building</li>
              <li>Impactful Outreach</li>
            </ul>
          </header>
          <div className='eventPage_img'>
            <img src={eventsHero} alt='' srcset='' />
          </div>
        </div>
        <div className='teamPage__section'>
          <header className='teamPage__section-header'>
            <h1 className='gradient__text-primary'>Upcoming Events</h1>
          </header>
          <div className='teamPage__section-body'>
            {/* <div className='eventCard__container'>
              <h1 className='eventCard__body__heading'>Coming Soon!</h1>
            </div> */}
            <EventCard
              image={aiEvent}
              details='19 June 2024'
              heading='The Season of AI - Build Your First Copilot'
              description={`It's the Season of AI and we need to keep ourselves updated with its trends. Keeping yourself updated doesn't mean going Pro, no. It can be something basic, "What is Generative AI?" Come, let's explore the AI landscape. At the end of this event, you will be able to build your own first Copilot - your AI companion. Looking forward to seeing to soon!
              `}
              organizers={[
                <ProfileCard name='Muhammad Zia Un Nabi Janjua' position='President' fileName={zia} link='https://www.linkedin.com/in/rajazia442/' />,
              ]}
              detailsLink={`https://forms.microsoft.com/r/gTQ6G4wa0G`}
            />
            <EventCard
              image={techEvent}
              details='17 August 2024'
              heading='TechRise - Explore Tech Careers'
              description={`Join us at TechRise, an exciting event designed to empower students from North Pakistan to explore diverse tech careers. Discover which tech career suits you, learn essential skills, and connect with industry experts through insightful workshops, career exploration sessions, and networking opportunities. Gain valuable insights into open source, Git, GitHub, and Microsoft education technologies, and receive a personalized career roadmap to guide your journey. Don’t miss this opportunity to kickstart your tech career with confidence!
              `}
              organizers={[
                <ProfileCard name='Muhammad Zia Un Nabi Janjua' position='President' fileName={zia} link='https://www.linkedin.com/in/rajazia442/' />,
                <ProfileCard name='Asad Ullah Qadir' position='Organizer' fileName={asadQadir} link='' />,
              ]}
              detailsLink={`https://forms.microsoft.com/r/u9NXyANrsi`}
            />
          </div>
        </div>
        <div className='teamPage__section'>
          <header className='teamPage__section-header'>
            <h1 className='gradient__text-secondary'>Past Events</h1>
          </header>
          <div className='events_page-body'>
            {currentEvents.map((event, index) => (
              <EventCard
                key={index}
                image={event.image}
                details={event.details}
                heading={event.heading}
                link={event.link}
                description={event.description}
                organizers={event.organizers}
                detailsLink={event.detailsLink}
              />
            ))}
          </div>
          <div className='pagination'>
            Page:
            {Array.from({ length: totalPages }, (_, i) => (
              <button key={i} className={i + 1 === currentPage ? 'active' : ''} onClick={() => handlePageChange(i + 1)}>
                {i + 1}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventPage;
