import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';
import community from '../../assets/partneredUp-cropped.svg';
import mlsaLogo from '../../assets/mlsaszabist_logo.png';
import gdscLogo from '../../assets/gdsc-szabist-isb-logo.png';
// import githubCampusLogo from '../../assets/github_campus_expert.png';
import { motion } from 'framer-motion';

const Features = () => (
  <motion.div
    initial={{ opacity: 0 }}
    whileInView={{ opacity: 1 }}
    transition={{ duration: 0.8 }}
    viewport={{ once: true }}
    className='tca__features section__padding'
    id='tech'
  >
    <div className='tca__features-heading'>
      <h1 className='gradient__text-secondary'>Community Partners</h1>
      <p>
        The Community Alliance (TCA) thrives on collaboration and partnerships to fulfill its mission of creating positive change within communities.
        Our community partners play a pivotal role in amplifying our impact and expanding our reach.{' '}
      </p>
      <img src={community} alt='' srcset='' className='main_img' />
    </div>
    <div className='tca__features-container'>
      <div className='community_Partner_container'>
        <Feature
          title='MLSA - SZABIST Islamabad'
          text='The Microsoft Learn Student Ambassador SZABIST Islamabad Chapter is an initiative of Microsoft to connect students and tech enthusiasts. It aims to help fellow students lead in their local tech community and develop technical and career skills for the future.'
        />
        <img src={mlsaLogo} alt='' className='community_Partner_logo' />
      </div>
      <div className='community_Partner_container'>
        <Feature
          title='GDSC - SZABIST Islamabad'
          text='Google Developers Student Club SZABIST Islamabad, powered by Google Developers is a non-profit community that aims to inspire great minds to innovate in the field of technologies provided by Google.'
        />
        <img src={gdscLogo} alt='' className='community_Partner_logo' style={{ maxHeight: '100px' }} />
      </div>
      {/* <div className='community_Partner_container'>
        <Feature
          title='GitHub Campus Experts'
          text='Campus Experts are student leaders that strive to build diverse and inclusive spaces to learn skills, share their experiences, and build projects together.'
        />
        <img src={githubCampusLogo} alt='' className='community_Partner_logo' style={{ maxHeight: '100px' }} />
      </div> */}
    </div>
  </motion.div>
);

export default Features;
