import React, { createContext } from 'react';
import './App.css';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';

import Navbar from './components/navbar/Navbar';
import Footer from './containers/footer/Footer';
import HomePage from './pages/homePage/HomePage';
import TeamPage from './pages/teamPage/TeamPage';
import EventPage from './pages/eventPage/EventPage';
import COC from './pages/codeOfConduct/COC';
import NotFoundPage from './pages/notFoundPage/NotFoundPage';

export const ThemeContext = createContext(null);

const App = () => {
  const location = useLocation();

  return (
    <div className='App'>
      <Navbar />
      <Routes key={location.pathname} location={location}>
        <Route path='/home' exact element={<HomePage />} />
        <Route path='/' element={<Navigate replace to='/home' />} />
        <Route path='/team' exact element={<TeamPage />} />
        <Route path='/events' exact element={<EventPage />} />
        {/* <Route path='/zabcon' exact element={<ZabTechEvent />} /> */}
        <Route path='/code-of-conduct' exact element={<COC />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
