import React from 'react';
import './COC.css';
import cocHero from '../../assets/code-of-conduct-cropped.svg';

const COC = () => {
  return (
    <div className='animate__animated animate__fadeIn'>
      <div className='teamPage__container'>
        <div className='teamPage__section_main'>
          <header className='teamPage__section-header'>
            <h1 className='gradient__text-secondary' style={{ marginBottom: '1rem' }}>
              Code of Conduct for The Community Alliance!
            </h1>
            <p>
              Our community is committed to fostering a respectful, inclusive, and collaborative environment for all members. Our code of conduct sets
              the standards of behaviour and ensures a positive experience for everyone involved. It promotes integrity, professionalism, and ethical
              behaviour while encouraging knowledge-sharing, teamwork, and growth. We value diversity and respect the rights and opinions of all
              individuals. By adhering to this code, we create a safe space for learning, innovation, and personal development within our community.
            </p>
          </header>
          <div className='eventPage_img'>
            <img src={cocHero} alt='Code of Conduct illustration' />
          </div>
        </div>
        <div className='coc__section'>
          <header className='coc_header'>
            <h1 className='gradient__text-secondary'>Professionalism:</h1>
          </header>
          <div className='coc_body'>
            <ul>
              <li>Conduct yourself professionally in all interactions within the community, both online and offline.</li>
              <li>Communicate respectfully and courteously, maintaining a positive and professional tone.</li>
              <li>Respect the confidentiality of sensitive information shared within the community.</li>
            </ul>
          </div>
        </div>
        <div className='coc__section'>
          <header className='coc_header'>
            <h1 className='gradient__text-secondary'>Demonstrating Support:</h1>
          </header>
          <div className='coc_body'>
            <ul>
              <li>Act as a trusted voice within the community alliance, working in the best interest of the people we serve.</li>
              <li>Provide valuable insights, facts, and opinions to contribute to informed public debates within the commercial circle.</li>
            </ul>
          </div>
        </div>
        <div className='coc__section'>
          <header className='coc_header'>
            <h1 className='gradient__text-secondary'>Commitment to Honesty:</h1>
          </header>
          <div className='coc_body'>
            <ul>
              <li>
                Uphold the highest standards of accuracy and integrity when advocating for targeted individuals and engaging with the broader public.
              </li>
              <li>Promote transparency and honesty in all communications and actions.</li>
            </ul>
          </div>
        </div>
        <div className='coc__section'>
          <header className='coc_header'>
            <h1 className='gradient__text-secondary'>Upholding Independence:</h1>
          </header>
          <div className='coc_body'>
            <ul>
              <li>Take full accountability for our actions and decisions, maintaining independence in judgments and operations.</li>
              <li>Demonstrate unwavering dedication to serving the public interest and those within the community alliance.</li>
            </ul>
          </div>
        </div>
        <div className='coc__section'>
          <header className='coc_header'>
            <h1 className='gradient__text-secondary'>Loyalty and Fairness:</h1>
          </header>
          <div className='coc_body'>
            <ul>
              <li>Display loyalty and commitment to serving the public interest and the individuals we address within the community alliance.</li>
              <li>Treat clients, employers, competitors, peers, vendors, the media, and the general public with fairness, respect, and integrity.</li>
              <li>Embrace diverse opinions and support the right to free expression, fostering an inclusive environment.</li>
            </ul>
          </div>
        </div>
        <div className='coc__section'>
          <header className='coc_header'>
            <h1 className='gradient__text-secondary'>Do's for the community alliance:</h1>
          </header>
          <div className='coc_body'>
            <ol>
              <li>
                Respect and inclusivity: Treat everyone in the community with respect and value their contributions, regardless of their background or
                characteristics.
              </li>
              <li>
                Collaborative and constructive communication: Engage in conversations and discussions that are respectful, constructive, and focused
                on the topic at hand.
              </li>
              <li>Supportive and encouraging environment: Foster a culture of support, encouragement, and mentorship among community members.</li>
              <li>
                Reporting mechanism: Establish a clear and accessible reporting mechanism for incidents of harassment, discrimination, or
                inappropriate behavior within the community.
              </li>
              <li>
                Education and awareness: Promote education and awareness about diversity, inclusion, and appropriate behavior within the community.
              </li>
            </ol>
          </div>
        </div>
        <div className='coc__section'>
          <header className='coc_header'>
            <h1 className='gradient__text-secondary'>Don'ts for the community alliance:</h1>
          </header>
          <div className='coc_body'>
            <ol>
              <li>
                Discrimination and harassment: Do not engage in any form of discrimination, harassment, or bullying based on characteristics such as
                gender, race, ethnicity, religion, or sexual orientation.
              </li>
              <li>
                Offensive language and behavior: Avoid using offensive language, making derogatory remarks, or engaging in any behavior that may
                create an uncomfortable or hostile environment.
              </li>
              <li>
                Unauthorized use of resources: Do not use the community's resources or platforms for illegal activities, plagiarism, or unauthorized
                distribution of copyrighted material.
              </li>
              <li>
                Retaliation: Refrain from any form of retaliation against individuals who report incidents or express concerns about misconduct within
                the community.
              </li>
              <li>
                Disruption of community activities: Avoid disruptive behavior, excessive noise, or actions that hinder the smooth functioning of
                community events or activities.
              </li>
            </ol>
          </div>
        </div>
        <div className='coc__section'>
          <header className='coc_header'>
            <h1 className='gradient__text-secondary'>Safe and Inclusive Events:</h1>
          </header>
          <div className='coc_body'>
            <ul>
              <li>Ensure that all community events are safe, inclusive, and welcoming to all participants.</li>
              <li>Prohibit any form of discrimination, harassment, or exclusionary behavior during events.</li>
              <li>Provide accessible venues and accommodations, taking into consideration the needs of diverse participants.</li>
            </ul>
          </div>
        </div>
        <div className='coc__section'>
          <header className='coc_header'>
            <h1 className='gradient__text-secondary'>Compliance with Community Guidelines:</h1>
          </header>
          <div className='coc_body'>
            <ul>
              <li>Familiarize yourself with and adhere to the community guidelines and policies.</li>
              <li>Follow the directions of community leaders and organizers, and respect their decisions.</li>
              <li>Encourage fellow community members to uphold the code of conduct.</li>
            </ul>
          </div>
        </div>
        <div className='coc__section'>
          <header className='coc_header'>
            <h1 className='gradient__text-secondary'>Consent and Privacy:</h1>
          </header>
          <div className='coc_body'>
            <ul>
              <li>Respect the personal boundaries, privacy, and consent of others, both online and offline.</li>
              <li>Obtain explicit consent before recording, sharing, or publishing any content involving community members.</li>
            </ul>
          </div>
        </div>
        <div className='coc__section'>
          <header className='coc_header'>
            <h1 className='gradient__text-secondary'>Reporting and Accountability:</h1>
          </header>
          <div className='coc_body'>
            <ul>
              <li>Report any violations of this code of conduct promptly to the designated community leaders or authorities.</li>
              <li>Support a fair and impartial investigation process and cooperate in resolving reported incidents.</li>
            </ul>
          </div>
        </div>
        <div className='coc__section'>
          <header className='coc_header'>
            <h1 className='gradient__text-secondary'>Upholding the Code of Conduct:</h1>
          </header>
          <div className='coc_body'>
            <ul>
              <li>Familiarize yourself with the code of conduct and ensure your actions align with its principles.</li>
              <li>
                Encourage and support fellow community members in upholding the code of conduct to maintain a positive and inclusive tech community.
              </li>
            </ul>
          </div>
        </div>
        <div className='coc__section'>
          <div className='coc_body'>
            <p>
              By participating in The Community Alliance, you agree to abide by this code of conduct. The community leaders reserve the right to take
              appropriate action, including warning, temporary suspension, or permanent expulsion from the community, in response to violations of
              this code of conduct.
            </p>
          </div>
        </div>
        <div className='coc__section'>
          <header className='coc_header'>
            <h1 className='gradient__text-secondary'>Need Help With a Violation or Incident?</h1>
          </header>
          <div className='coc_body'>
            <p>For any concerns feel free to reach out to me If you feel unsafe, unwelcome, or uncomfortable within the community alliance</p>
            <ul>
              <li>
                <a href='mailto:communityalliance.tca@gmail.com' style={{ textDecoration: 'underline' }}>
                  Email us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default COC;
