import React from 'react';
import './cta.css';

const CTA = () => {
  return (
    <div className='tca__cta'>
      <div className='tca__cta-content'>
        <p>The Community Alliance (TCA)</p>
        <h3>Join and become a member of the community!</h3>
      </div>
      <div className='tca__cta-btn'>
        <button type='button' className='getStartedButton'>
          <a
            href='https://forms.microsoft.com/pages/responsepage.aspx?id=oBzDhDusrk6tEVGdgCM-b0RxnAoboPlFgIgpaFieR5pUM0MwRTVNRlhKOFdXNFVBVVlJQ0o3Q0ZWMC4u'
            target='_blank'
            rel='noreferrer'
          >
            Become a Member
          </a>
        </button>
      </div>
    </div>
  );
};

export default CTA;
