import React from 'react';
import './profileCard.css';
import linkedin from '../../assets/linkedin.png';
const ProfileCard = ({ name, position, fileName, link }) => {
  return (
    <div className='card__container'>
      <div className='card__body'>
        <header className='card__body-header'>
          <div className='card__body-header-img-container'>
            <img src={fileName} alt={name} className='profile-image' />
          </div>
        </header>
        <div className='card__body-content'>
          <p className='name'>{name}</p>
          <p className='position'>{position}</p>
          <a href={link} target='_blank' rel='noreferrer'>
            <img src={linkedin} alt='Linkedin Link' />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
