import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import './eventCard.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const EventCard = ({ image, details, heading, description, link, organizers, detailsLink }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div className='eventCard__container'>
        <header className='eventcard__header'>
          <img src={image} alt='event poster' />
        </header>
        <div className='eventCard__body'>
          <p className='eventCard__details'>{details}</p>
          <h1 className='eventCard__body__heading'>{heading}</h1>
          <p className='eventCard__description'></p>
          {link ? (
            <a href={link} target={'_blank'} rel='noreferrer'>
              <button className='eventFullDetails'>View Details</button>
            </a>
          ) : (
            <button className='eventFullDetails' onClick={handleClickOpen}>
              View Details
            </button>
          )}
        </div>
      </div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative', backgroundColor: '#000' }}>
          <Toolbar>
            <IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
              Event Details
            </Typography>
          </Toolbar>
        </AppBar>
        <div className='animate__animated animate__fadeIn'>
          <div className='teamPage__container'>
            <div className='teamPage__section_main'>
              <header className='teamPage__section-header'>
                <h1 className='gradient__text-secondary'>{heading}</h1>
                <h3 className='gradient__text-secondary' style={{ margin: '1rem 0' }}>
                  Details: {details}
                </h3>
                <p>{description}</p>
                <a href={detailsLink} target={'_blank'} rel='noreferrer' style={{ margin: '1.5rem' }}>
                  <button className='eventFullDetails'>View Details</button>
                </a>
              </header>
              <div className='eventPage_img'>
                <img src={image} alt='' srcset='' />
              </div>
            </div>
            <div className='teamPage__section'>
              <header className='teamPage__section-header'>
                <h1 className='gradient__text-primary'>Organizers</h1>
              </header>
              <div className='teamPage__section-body'>{organizers}</div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default EventCard;
