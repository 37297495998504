import React from 'react';
import Header from '../../containers/header/Header';
import WhatTCA from '../../containers/whatTCA/WhatTCA';
import Features from '../../containers/features/Features';
import CTA from '../../components/cta/CTA';
import 'animate.css';

const HomePage = () => {
  return (
    <div className='App animate__animated animate__fadeIn'>
      <Header />
      <WhatTCA />
      <Features />
      <CTA />
    </div>
  );
};

export default HomePage;
